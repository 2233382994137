<template>
  <div>
    <p
      class="frmValidation"
      :class="{ 'frmValidation--passed': has_lenght }"
    >
      <i
        class="frmIcon vs-icon notranslate icon-scale vs-icon-noti feather"
        :class="has_lenght ? 'icon-check-circle' : 'icon-x-circle'"
      ></i>
      {{ $t("MaisDeSeisCaracteres") }}
    </p>
    <p
      class="frmValidation"
      :class="{ 'frmValidation--passed': has_uppercase }"
    >
      <i
        class="frmIcon vs-icon notranslate icon-scale vs-icon-noti feather"
        :class="has_uppercase ? 'icon-check-circle' : 'icon-x-circle'"
      ></i>
      {{ $t("TemUmaLetraMaiuscula") }}
    </p>
    <p
      class="frmValidation"
      :class="{ 'frmValidation--passed': has_lowercase }"
    >
      <i
        class="frmIcon vs-icon notranslate icon-scale vs-icon-noti feather"
        :class="has_lowercase ? 'icon-check-circle' : 'icon-x-circle'"
      ></i>
      {{ $t("TemUmaLetraMinuscula") }}
    </p>
    <p class="frmValidation" :class="{ 'frmValidation--passed': has_number }">
      <i
        class="frmIcon vs-icon notranslate icon-scale vs-icon-noti feather"
        :class="has_number ? 'icon-check-circle' : 'icon-x-circle'"
      ></i>
      {{ $t("TemUmNumero") }}
    </p>
  </div>
</template>

<script>
export default {
    props: {
        password: {
            type: [Number, String, Object, Array, null]
        },
        has_uppercase: {
            type: Boolean,
            default: false
        },
        has_lowercase: {
            type: Boolean,
            default: false
        },
        has_number: {
            type: Boolean,
            default: false
        },
        has_lenght: {
          type: Boolean,
          default: false
        }
    },
    data () {
        return {
            _password: ''
        }
    },
    watch: {
        password: {
            immediate: true,
            handler (val) {
                this._password = val
            }
        }
    },
};
</script>

<style>
.frmField {
  background-color: white;
  color: #495057;
  line-height: 1.25;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  border: 0;
  padding: 10px;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  width: 90%;
}
.frmLabel {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}
.frmValidation {
  font-size: 13px;
}
.frmValidation--passed {
  color: #717b85;
}
.frmIcon {
  color: #eb0029;
}
.frmValidation--passed .frmIcon {
  color: #0fa140;
}
</style>